<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="list1">
            <div class="item">
                <div class="top">
                    <div class="left">
                        <img :src="detail.avatar ? detail.avatar : require('../../assets/images/default_avatar.jpeg')" alt="" />
                        <div class="column">
                            <span class="name">{{ detail.communityName }}</span>
                            <span class="time">{{ detail.createTime }}</span>
                        </div>
                    </div>
                    <div class="right">
                        <div class="items" @click.stop="collection()">
                            <img :src="detail.collectionId ? require('../../assets/images/Frame-32.png') : require('../../assets/images/Frame-31.png')" />
                            <span>{{ detail.collectionCount || 0 }}</span>
                        </div>
                        <div class="items">
                            <img src="@/assets/images/Group622.png" alt="" />
                            <span>{{ total || 0 }}</span>
                        </div>
                        <div class="items" @click.stop="praise()">
                            <img :src="detail.praiseId ? require('../../assets/images/Frame-30.png') : require('../../assets/images/Frame-29.png')" />
                            <span>{{ detail.praiseCount || 0 }}</span>
                        </div>
                    </div>
                </div>
                <div class="content">{{ detail.content }}</div>
                <div class="img">
                    <div class="" v-for="(t, i) in detail.picUrl" :key="i" @click.stop="() => {}">
                        <el-image style="width: 100%; height: 130px" :src="t" :preview-src-list="detail.picUrl"></el-image>
                    </div>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <div class="top">
                    <div class="left">
                        <img :src="item.memberIcon ? item.memberIcon : require('../../assets/images/default_avatar.jpeg')" alt="" />
                        <div class="column">
                            <span class="name">{{ item.createBy }}</span>
                            <span class="time">{{ item.createTime }}</span>
                        </div>
                    </div>
                </div>
                <div class="content">{{ item.commentContent }}</div>
            </div>
        </div>
        <div class="comment">
            <el-input resize="none" type="textarea" :rows="7" placeholder="请输入内容" v-model="comment"></el-input>
            <span @click="addCommunityComent">发布回复</span>
        </div>
        <el-pagination @size-change="getCommunityComentList" @current-change="getCommunityComentList" :current-page.sync="page" :page-sizes="[10, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'detail',
    data() {
        return {
            tabs: 0,
            total: 0,
            limit: 10,
            page: 1,
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '社群',
                    to: { path: '/communityList' },
                },
                {
                    title: '帖子详情',
                },
            ],
            comment: '',
            detail: {},
            list: [],
        };
    },
    created() {
        this.getDetail();
        this.getCommunityComentList();
    },
    methods: {
        getData() {
            console.log(this.page, this.limit);
        },
        getDetail() {
            this.$hao.getCommunityPostsDetail(this.$route.query.id).then((res) => {
                console.log(res);
                res.data.picUrl = res.data.picUrl.split(',');
                this.detail = res.data;
            });
        },
        getCommunityComentList() {
            let data = {
                pageNum: this.page,
                pageSize: this.limit,
                postsId: this.$route.query.id,
            };

            this.$hao.getCommunityComentList(data).then((res) => {
                console.log(res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
        addCommunityComent() {
            if (!this.comment) {
                this.$message.error('请输入内容');
                return;
            }
            console.log(localStorage.getItem('userInfo'));
            let data = {
                postsId: this.$route.query.id,
                commentContent: this.comment,
                mesType: 0,
                userId: JSON.parse(localStorage.getItem('userInfo')).userId,
            };
            this.$hao.addCommunityComent(data).then((res) => {
                if (res.code == 200) {
                    console.log(res);
                    this.$message.success('评论成功');
                    this.comment = '';
                    this.page = 1;
                    this.getCommunityComentList();
                }
            });
        },
        // 收藏
        collection() {
            let item = this.detail;
            if (item.collectionId === null) {
                this.$hao.addPraiseCollection({ relatedId: item.id, type: 4 }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('收藏成功');
                        item.collectionId = res.data.id;
                        item.collectionCount++;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$hao.delPraiseCollection(item.collectionId).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('取消收藏成功');
                        item.collectionId = null;
                        item.collectionCount--;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 点赞
        praise() {
            let item = this.detail;
            if (item.praiseId === null) {
                this.$hao.addPraiseCollection({ relatedId: item.id, type: 8 }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('点赞成功');
                        item.praiseId = res.data.id;
                        item.praiseCount++;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$hao.delPraiseCollection(item.praiseId).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('取消点赞成功');
                        item.praiseId = null;
                        item.praiseCount--;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
.list1 {
    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-bottom: 12px;
        padding: 42px;

        .top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;

                .column {
                    display: flex;
                    flex-direction: column;
                    .name {
                        margin-right: 8px;
                        font-weight: 700;
                        font-size: 19px;
                        color: #333333;
                    }
                    .time {
                        font-size: 14px;
                        color: #999999;
                    }
                }

                img {
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }

            .right {
                display: flex;
                align-items: center;

                .items {
                    display: flex;
                    align-items: center;
                    margin-left: 24px;

                    img {
                        width: 18px;
                        height: 18px;
                        margin-right: 4px;
                    }

                    span {
                        font-size: 14px;
                        line-height: 20px;
                        color: #797a87;
                    }
                }
            }
        }

        .content {
            padding: 12px 0;
            font-size: 16px;
            line-height: 17px;
            color: #333333;
        }

        .img {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 4px;
            width: 50%;

            img {
                width: 100%;
                margin-top: 4px;
            }
        }
    }
}

.list {
    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-bottom: 12px;
        padding: 42px;
        margin-top: 10px;

        .top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;

                .column {
                    display: flex;
                    flex-direction: column;
                    .name {
                        margin-right: 8px;
                        font-weight: 700;
                        font-size: 19px;
                        color: #333333;
                    }
                    .time {
                        font-size: 14px;
                        color: #999999;
                    }
                }

                img {
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }
        }

        .content {
            padding-top: 24px;
            font-size: 16px;
            line-height: 17px;
            color: #333333;
        }
    }
}
.comment {
    width: 100%;
    height: 160px;
    position: relative;
    border: 1px solid #ebebeb;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.06);
    ::v-deep textarea {
        background: #eff1f7 !important;
    }
    span {
        position: absolute;
        right: 12px;
        bottom: 12px;
        font-size: 16px;
        line-height: 44px;
        cursor: pointer;
        width: 120px;
        height: 44px;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        background: #eda200;
        border-radius: 2px;
    }
}
</style>
